import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="20 -70 550 550" width="50px" height="50px">
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path
          d="M403.496,101.917c-4.104-5.073-8.877-9.705-14.166-13.839c0.707,13.117-0.508,27.092-3.668,41.884    c-8.627,40.413-29.256,74.754-59.656,99.304c-30.375,24.533-68.305,37.502-109.686,37.502h-60.344l-19.533,91.512    c-3.836,17.959-19.943,30.99-38.303,30.99H70.938l-4.898,22.484c-1.258,5.79,0.17,11.839,3.887,16.453    c3.715,4.614,9.324,7.298,15.25,7.298h66.498c9.24,0,17.225-6.459,19.152-15.495L193.667,313h76.188    c36.854,0,70.527-11.464,97.384-33.152c26.869-21.697,45.129-52.186,52.807-88.162    C427.822,155.309,422.253,125.106,403.496,101.917z"
          fill="#ccd6f6"
        />
        <path
          d="M117.292,354.191l22.84-107.008h76.188c36.852,0,70.527-11.465,97.383-33.154c26.867-21.697,45.129-52.186,52.809-88.161    c7.773-36.378,2.207-66.58-16.553-89.769C331.952,13.832,301.17,0,269.633,0H103.639c-9.209,0-17.174,6.417-19.135,15.414    L12.505,345.938c-1.26,5.789,0.168,11.838,3.887,16.453c3.713,4.613,9.32,7.296,15.248,7.296h66.5    C107.38,369.687,115.36,363.229,117.292,354.191z M178.235,75.291h52.229c12.287,0,23.274,5.149,30.145,14.129    c7.297,9.539,9.431,22.729,5.853,36.188c-0.047,0.171-0.088,0.342-0.131,0.516c-6.57,27.73-33.892,50.291-60.898,50.291h-50.05    L178.235,75.291z"
          fill="white"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
